import { FC, useEffect } from 'react'
import { Heading, Paragraph } from '../../../../@components'
import { FavouriteList } from './components'
import { ReactComponent as BackIcon } from '../../../../assets/icon/back.svg'
import s from './HeaderSection.module.scss'
import { observer } from 'mobx-react-lite'
import { RootStore } from '../../../../store'
import { SearchBar } from '../../../../@components/Search/SearchBar'
import { SearchDropDown } from '../../../../@components/Search/SearchDropDown'
import classNames from 'classnames'

interface IProps {
  onClickPlus?: () => void;
}

export const HeaderSection: FC<IProps> = observer((props) => {
  const searchStore = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore

  const { isSearchOpen, closeSearch, fetchPopular } = searchStore
  const { favourites } = favouritesStore

  useEffect(() => {
    fetchPopular()
  }, [])

  return (
    <div className={s.container}>
      <section className={classNames(
        isSearchOpen ? s.headerFixed : s.header, 
        (!isSearchOpen && favouritesStore.favourites.length) && s.headerMobileHidden
      )}>
        <div className={isSearchOpen ? s.searchSection : s.searchSectionBeforeOpen}>
          <div onClick={closeSearch} className={s.backButton}>
            <BackIcon />
          </div>
          <SearchBar 
            classForWrapper={s.searchBar}
            onEscape={closeSearch} 
          />
        </div>
        <div className={isSearchOpen ? s.equalEmptySpace : s.equalEmptySpaceBeforeOpen} />
      </section>

      <SearchDropDown className={isSearchOpen ? s.dropDownFixed : s.dropDown} onClickPlus={props.onClickPlus}/>

      {favourites.length === 0 && (
        <section className={isSearchOpen ? s.hiddenContent : s.content}>
          <div className={s.inner}>
            <Heading as='h1'>Умная система рекомендаций</Heading>
            <Paragraph>Насладиться выгодной подпиской KION+Premium тебе поможет умная система рекомендаций</Paragraph>
          </div>
          <div className={s.posters}>
            {/* TODO: Рефакторинг - тут должен быть просто компонент с плюсом */}
            <FavouriteList onClickPlus={props.onClickPlus}/>
          </div>
        </section>
      )}
    </div>
  )
})
