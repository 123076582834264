import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import layout from '../../assets/layout.webp'

export const PostersBackground: FC<PropsWithChildren> = observer(({ children }) => <Container>{children}</Container>)

const Container = styled.div`
  background-image: url(${layout});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: calc(100vh + 70px);
  height: 100%;
  padding-bottom: 70px;
`
