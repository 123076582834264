import {v4 as uuidv4} from "uuid"

export type DLEvent = "hello_search" | "hello_button_click"

export type DLEventProfile = 'mts_guest' | string

export interface DataLayer {
    event?: DLEvent | null
    event_name?: DLEvent | null
    screen?: string | null
    userId?: string | null
    client_id?: string | null // берется из яндекс метрики
    event_datetime?: string | null
    platform?: string | null // web30_landing_hello
    huawei_subscriber_id?: string | null // 'mts_guest',

    term?: string | null //"текст поиска"
    search_films?: string[] | null // <массив ид фильмов, по которым ИЩУТ>
    recommended_films?: string[] | null // <массив ид фильмов, которые РЕКОМЕНДУЕМ>,
    button_id?: string | null
    button_text?: string | null

    session_id?: string
}

declare const dataLayer: DataLayer[]

export class GTMService {
    public sessionId = uuidv4()

    public pushEvent(_dl: DataLayer = {}) {
        const data = {
            ..._dl
        }

        if (process.env.NODE_ENV === 'production') {
            console.info('data pushed');
            dataLayer.push(data);
        } else {
            console.info('[GTM] Data pushed to dataLayer', data)
        }
    }
}

export const gtm = new GTMService()