import { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Button, Poster } from '../../../../../../../@components'
import { ReactComponent as CrossIcon } from '../../../../../../../assets/icon/cross.svg'
import { observer } from 'mobx-react-lite'
import { Paragraph } from '../../../../../../../@components'
import { RootStore } from '../../../../../../../store'

interface IFavouriteElement {
  width: number
  offset: number
  imageUrl?: string
  idx: number
  id: string
  className?: string
  onAdd?: () => void
  onRemove?: () => void
  addParagraph?: boolean; // TODO: Временно, потом будет новый компонент для списка выбранных фильмов
  onClickPlus?: () => void;
}

export const FavouriteElement: FC<IFavouriteElement> = observer((props) => {
  const searchStore = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore
  
  const isPlaceholder = props.imageUrl === 'placeholder'
  const x = props.idx * (props.width + Math.min(props.offset * props.idx, props.offset))
  const { openSearch } = searchStore

  function onClickNew() {
    if (props.imageUrl === 'placeholder') {
      openSearch();
      if (props.onClickPlus) props.onClickPlus()
    }    
  }

  function onRemove() {
    favouritesStore.removeFromFavourites(props.id)
    props.onRemove?.()
  }

  return (
    <Container
      className={props.className}
      initial={{ opacity: 0, scale: 0.7, left: x }}
      animate={{ opacity: 1, scale: 1, left: x, transition: { delay: 0.1, ease: 'easeOut' } }}
      exit={{ opacity: 0, scale: 0.7, left: x }}
      style={{ 
        width: props.width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {!isPlaceholder && (
        <RemoveControl onClick={onRemove}>
          <CrossIcon />
        </RemoveControl>
      )}
      <Poster 
        className="shadow-on-hover"
        src={props.imageUrl} 
        radius='1' 
        style={{ width: props.width }}
        onClick={onClickNew}
      />
      {props.addParagraph && (
        <Paragraph>
          Нажми, чтобы начать
        </Paragraph>
      )}
    </Container>
  )
})

const RemoveControl = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transform: scale(0.9);
  transition: all 300ms ease-in;
  z-index: 1;
  cursor: pointer;
`

const Container = styled(motion.div)`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  position: absolute;

  &:not(:first-of-type):before {
    position: absolute;
    top: calc(50% - (3.25rem / 2));
    left: -1.375rem;
    font-size: 1.5rem;
    color: var(--color-white);

    @media screen and (min-width: 1440px) {
      content: '+';
    }
  }

  &:hover ${RemoveControl} {
    opacity: 1;
    transform: scale(1);
  }
`

const AttachButton = styled(Button)`
  min-width: 100%;
`
