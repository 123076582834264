import React, { FC, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { HeaderSection, Footer, CollectionSection } from './components'
import { observer } from 'mobx-react-lite'
import { RootStore } from '../../store'

interface IProps {
  onClickPlus?: () => void;
}

export const Main: FC<IProps> = observer((props) => {
  const searchStore = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore

  const refContainer = useRef<HTMLDivElement>(null)
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  
  useEffect(() => {
    setScrollbarWidth(window.innerWidth - (refContainer.current?.clientWidth || 0))
  }, [searchStore.isSearchOpen])

  return (
    <Container ref={refContainer}>
      <Mask 
        mask={searchStore.isSearchOpen || favouritesStore.favourites.length > 0} 
        scrollWidth={scrollbarWidth}
      />
      <Wrapper>
        <HeaderSection onClickPlus={props.onClickPlus}/>
        <CollectionSection />
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
  position: inherit;
`

const Mask = styled.div<{mask: boolean, scrollWidth: number}>`
    ${p => p.mask && css``}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  flex: 1 0 auto;
`
