import styled, { keyframes } from "styled-components"
import { mediaQueries } from "../../styles/global-styles"

export const LoaderWrapper = styled.div`
  flex: 1;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  width: 60px;
  height: 60px;
  border: 4px solid transparent;
  border-top-color: var(--color-secondary-grey);
  border-radius: 50%;
  animation: ${spin} 1s ease-in infinite;

  ${mediaQueries.mobile} {
    width: 45px;
    height: 45px;
    border: 3px solid transparent;
    border-top-color: var(--color-secondary-grey);
  }
`