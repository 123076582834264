import { FC } from 'react'
import styled from 'styled-components'
import { Heading, Paragraph } from '../../@components'
import { RatingsApi } from 'mgw-api'

interface IRatings extends RatingsApi {}

export const Ratings: FC<IRatings> = ({ mts, imdb, kinopoisk }) => {
  return (
    <Container>
      {/* {!!mts && (
        <Item>
          <StarIcon />
          <Heading as='h3' family='MTS Compact' weight={500} children={mts} />
        </Item>
      )} */}
      {!!imdb && (
        <Item>
          <Paragraph family='MTS Compact' size={'14px'} weight={400}>IMDB</Paragraph>
          <Paragraph family='MTS Compact' size={'14px'} weight={400}>{imdb}</Paragraph>
        </Item>
      )}
      {!!kinopoisk && (
        <Item>
          <Paragraph family='MTS Compact' size={'14px'} weight={400}>КП</Paragraph>
          <Paragraph family='MTS Compact' size={'14px'} weight={400}>{kinopoisk}</Paragraph>
        </Item>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: min-content;
  justify-self: center;
`

const Item = styled.div`
  display: flex;
  gap: 7px;
`
