import { FC, MouseEvent } from 'react'
import { Poster } from '../../../../../../../@components'
import { MetaResponseApi } from 'mgw-api'
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface ICollectionElement {
  className: string
  selected: MetaResponseApi
  width: number
  offset: number
  idx: number
  item: MetaResponseApi | 'placeholder'
  onClick: (ev: MouseEvent) => void
  isActive: boolean
}

export const CollectionElement: FC<ICollectionElement> = (props) => {
  const src = props.item === 'placeholder' ? 'placeholder' : props.item?.verticalImageUrl

  return (
    <Container
      className={props.className}
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1, transition: { delay: 0.1, ease: 'easeOut' } }}
      exit={{ opacity: 0, scale: 0.7, }}
      style={{ width: props.width }}
      onClick={props.onClick}
    >
      <Poster src={src} radius='1' />
    </Container>
  )
}

const Container = styled(motion.div)`
  position: relative;
  height: 100%;
  aspect-ratio: 0.72;
`
