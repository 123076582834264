import React, { FC, useEffect } from 'react'
import { Main } from './pages'
import { PostersBackground } from './@components'
import { getClientID } from './helpers/cookies'

interface IProps {
  onClick?: () => void;
}

export const Explore: FC<IProps> = (props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getClientID()) {
        clearInterval(intervalId)
      }
    }, 500)
  }, [])
  
  return (
    <PostersBackground>
      <Main onClickPlus={props?.onClick}/>
    </PostersBackground>
  )
}
