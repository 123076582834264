import styled from 'styled-components'

interface IProps {
  width?: string;
  height?: string;
}

export const Button = styled.button<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: ${p => p.width || 'fit-content' };
  padding: 10px 24px;
  min-height: 44px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  font-family: "MTS Sans";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5rem;
  color: white;
  text-decoration: none;

  transition: background 300ms linear;

  &[disabled] {
    cursor: not-allowed;
  }

  &.primary {
    background-image: linear-gradient(
      79.07deg,
      #3973b9 -61.91%,
      #355da8 -48.49%,
      #334093 -28.33%,
      #303189 -12.8%,
      #2e2b86 -3.89%,
      #c40d71 58%,
      #f01a5d 85.34%
    );
    background-size: 120% 120%;
    background-position: center;

    &:hover {
      background-position: 10%;
    }
  }

  &.secondary {
    background-color: #284059;
  }

  &.light {
    background: #dde1e7;
    color: #FFFFFF;

    &:hover {
      background: rgba(188, 195, 208, 0.5);
      color: #FFFFFF;
    }
  }

  &.ghost {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &.text {
    min-width: unset;
  }
`
