import { useState } from 'react'
import { PaymentStep } from '../subscription';
import { KionButton } from '../../button/button';
import { useNavigate } from 'react-router-dom';
import { PROMO, Subscription } from '../../../util/promo';
import { tariffPeriodSimpleTranslate } from '../../../util/helpers';
import { useStoreCtx } from '../../../store';
import { DataLayer, MtsBankWidgetConfig } from '../../../store/appStore';
import s from './style.module.scss'
import { AxiosResponse } from 'axios';
import { KION_ERROR_PAGE_TYPE } from '../../../util/interfaces';

declare global {
  interface Window {
    MTSPay: any;
  }
}

export function PaymentConfirm(props: {
    subscription: Subscription
    paymentMethod: PaymentStep
    onNext: (val: PaymentStep) => void
}) {
  const httpService = useStoreCtx().appStore

  const routerPush = useNavigate()
  const [processingTransaction, setProcessingTransaction] = useState(false)
  const [processText, setProcessText] = useState('')
  const textWithPromoPeriod = 
      'Вы пытаетесь оформить покупку «' 
      + props.subscription.name 
      + '» - ' 
      + '249&nbsp;₽ за 3 мес.' 
      + ' Далее&nbsp;549&nbsp;₽ за 3 мес.' 
      + ' Продолжить?';

  const commonDataLayer: DataLayer = {
    event: 'subscription_success',
    event_name: 'subscription_success',
    referer: '/subscriptions',
    // price: props.subscription.discountPrice,
    price: 2490000,
    subscription_name: props.subscription.name,
    subscription_id: props.subscription.productId,
    deeplink: null,
    request_id: null,
    is_trial: 0,
  }

  function onPreviousStep() {
    props.onNext(PaymentStep.Init)
  }

  function pay() {
    setProcessingTransaction(true)
    if (props.paymentMethod === PaymentStep.Phone) {
      payWithPhone()
          .then((el: AxiosResponse<any>) => {
            if (el.status === 200) {
                routerPush({pathname: "/success", search: httpService.getAnalyticsQuery()});
                httpService.pushEvent({
                    ...commonDataLayer,
                    payment_type: 'vps',
                })
            } else pushError('Request failed with status code' + el.status)
          })
          .catch((e) => {
            console.log(e);
            console.log(e.response);
            console.log(e.response.status);
            console.log(e.response.data);
            console.log(e.response.data.errorCode);
            console.log(KION_ERROR_PAGE_TYPE.BALANCE_NOT_ENOUGH);

            window.sessionStorage.removeItem("request_id")
            if (e.response.status === 403 && e.response.data?.errorCode === KION_ERROR_PAGE_TYPE.BALANCE_NOT_ENOUGH) {
              pushError(KION_ERROR_PAGE_TYPE.BALANCE_NOT_ENOUGH, KION_ERROR_PAGE_TYPE.BALANCE_NOT_ENOUGH)
            } else pushError(e.message)
          })
      return
    }
    if (props.paymentMethod === PaymentStep.Card) {
      payWithCard()
      return
    }
  }

  function pushError(msg: string, errType?: string) {
    console.log('pushError', msg)

    httpService.pushEvent({
      event: 'subscribe_error',
      event_name: 'subscribe_error',
      price: props.subscription.price,
      subscription_name: props.subscription.name,
      subscription_id: props.subscription.productId,
      promocode: '',
      error_message: msg,
      request_id: null
    })

    const analyticsParams = httpService.getAnalyticsQuery()
    let params = analyticsParams ? analyticsParams : ''
    if (errType) {
      if (params) params += `&type=${errType}`
      else params += `?type=${errType}` 
    }

    routerPush({
      pathname: "/error", 
      search: params,
    });
  }

  function payWithPhone() {
    setProcessingTransaction(true)
    return httpService.payWithPhone(props.subscription)
  }

  const [successEventPushed, setSuccessEventPushed] = useState(false)
  async function payWithCard() {
    setProcessingTransaction(true)
    try {
      await injectWidget()

      const cfg = await httpService.getWidgetConfig(props.subscription, async (res) => {
        // Виджет почему-то вызывает коллбек 2 раза (второй раз с эвентом "close")
        if (res.eventName === 'close') return;

        if (!res.isSuccess && res.eventName === 'failPayment') {
          pushError('failPayment')
        } else {
          const applyed = await checkSubscription(props.subscription)
          
          if (applyed) {
            routerPush({pathname: "/success", search: httpService.getAnalyticsQuery()});
            if (!successEventPushed) {
              setSuccessEventPushed(true)
              httpService.pushEvent({
                ...commonDataLayer,
                payment_type: 'mts_money',
              })
            }
          } else {
            pushError('subscription not applyed')
          }
        }
      })

      setupAndRunWidget(cfg)

    } catch (e) {
      pushError('pay with card: something went wrong')
    }
  }

  function injectWidget(fallback = false) {
    return new Promise((resolve, reject) => {
      let widget = document.createElement('script')

      widget.onload = () => {
        console.info('Widget installed')
        resolve('')
      }

      widget.onerror = (err) => {
        if (!fallback) {
          document.head.removeChild(widget)
          console.warn('Widget download failed. Try to install from older version')
          injectWidget(true).then(resolve).catch(reject)
        }
        if (fallback) {
          console.error('Widget installation failed.')
          reject(err)
        }
      }

      document.head.appendChild(widget)
      widget.src = fallback ? '/mts-bank-payment-widget.js' : 'https://pay.mts.ru/assets/js/web-sdk/v1/sdk.js'
    })
  }

  async function checkSubscription(s: Subscription): Promise<boolean> {
    setProcessText('Производится активация подписки...')
    let timeout = 2000
    for (let i = 0; i < 7; i++) {
      if (i === 0) timeout = 2000
      if (i === 1) timeout = 3000
      if (i > 1) timeout = 5000

      try {
        let res = await retry(s, timeout) as any[]
        
        if (res.length > 0) {
          httpService.subscriptionExist = true
          break
        }
      } catch (e) {
        pushError('check subscription: something went wrong when')
      }
    }
    return httpService.subscriptionExist
  }

  function retry(s: Subscription, timer: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        httpService.filterAppliedSubscriptions(s)
            .then(el => res(el.data))
            .catch(rej)
      }, timer)
    })
  }

  function  setupAndRunWidget(config: MtsBankWidgetConfig) {
    if (window.MTSPay) {
      const widget = new window.MTSPay(config)
      widget.render('bank-payment-widget')
      handleCloseButton()
      const shadowRoot = document.querySelector('#bank-payment-widget')?.shadowRoot
      shadowRoot?.addEventListener('click', () => {
        handleCloseButton()
      })
    }
  }

  function  handleCloseButton() {
    const intervalId = setInterval(() => {
      const root = document.querySelector('#bank-payment-widget')?.shadowRoot
      
      const closeButton = root?.querySelector('.mtsPayModalHeaderRightButton')
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          setProcessingTransaction(false);
          if (root) root.innerHTML = ''
        }, false)
      }

      // ! Виджет может полностью ререндериться
      // Поэтому ждем пока не найдется первый инпут (после этого считаем, что виджет полностью инициализирован и листенеры висят на всех кнопках закрытия)
      const hasInput = !!root?.querySelector('input')
      if (hasInput || root?.children.length === 0) clearInterval(intervalId);
    }, 500)
  }

  return (
      <>
        {!processingTransaction ? (
          <div className={s['payment-wrapper']}>
              <h2 className={s.title}>
              Подтвердить покупку
              </h2>
              <p className={s.description}>
              <span dangerouslySetInnerHTML={{__html: textWithPromoPeriod}}/>
              </p>
              <div className={s.actions}>
                  <KionButton 
                      negative={true}
                      onClick={onPreviousStep}
                  >
                      Отмена
                  </KionButton>
                  <KionButton onClick={pay}>
                      Продолжить
                  </KionButton>
              </div>

              <div className={s.offer}>
                  Нажимая кнопку «Подтвердить», Вы принимаете условия 
                  <br/> 
                  <a href="https://kion.ru/user-agreement" target={'_blank'}>
                      Пользовательского соглашения
                  </a>
              </div>
          </div>
        ) : (
          <div className={s['processing-wrapper']}>
              Пожалуйста, подождите <br />
              { processText }
          </div>
        )}
    </>
  )
}