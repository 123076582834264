import styled, { css } from 'styled-components'
import plus from '../../assets/icon/plus-new.svg'
import poster from "../../assets/poster.png"

interface IPoster {
  src?: string
  radius: '0.25' | '0.5' | '1'
}

export const Poster = styled.div<IPoster>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 193 / 268;
  border-radius: ${p => `${p.radius}rem`};
  position: relative;
  background-size: cover;

  /* &:before {
    content: '';
    background: var(--color-skeleton);
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
  } */

  &:before {
    font-weight: 700;
    font-size: 6.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
    user-select: none;
    font-family: CompactBold;
  }

  ${p =>
    p.src === 'placeholder' ? css`
    background-image: url(${poster});
    cursor: pointer;

    &:before {
      content: url(${plus});
      position: relative;
      z-index: 1;
    }
  ` : css`
    background-image: url(${p.src});
  `}
`
