import { makeAutoObservable } from 'mobx'
import { ShelfItemApi } from 'mgw-api'
import { CollectionStore } from './collection.store';

export class FavouritesStore {
  public favourites: ShelfItemApi[] = []

  collectionStore!: () => CollectionStore;
  constructor(collectionStore: () => CollectionStore) {
    this.collectionStore = collectionStore
    makeAutoObservable(this)
  }

  get positive(): string[] {
    const pos = this.favourites.reduce((acc, el) => {
      acc.add(el.gid)
      return acc
    }, new Set<ShelfItemApi['gid']>())

    return Array.from(pos)
  }

  public addToFavourites = (element: ShelfItemApi): void => {
    this.favourites.unshift(element)
  }

  public removeFromFavourites = (gid: string) => {
    const idx = this.favourites.findIndex(x => x.gid === gid)
    if (idx >= 0) this.favourites.splice(idx, 1)
    if (this.favourites.length === 0) this.collectionStore().refreshCollections()
  }

  public replaceFavourites = (element: ShelfItemApi, index: number): void => {
    this.favourites[index] = element
  }
}
