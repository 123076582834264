import { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { Slider, Heading, Paragraph } from '../../../../../../@components'
import { CollectionElement } from './CollectionElement'
import { Details } from './Details'
import { BreakpointsEnum, IBreakpoints } from '../../../../../../interfaces'
import { MetaResponseApi, ShelfItemApiTypeEnum } from 'mgw-api'
import { gtm } from '../../../../../../helpers/GTMService'
import { mediaQueries } from '../../../../../../styles/global-styles'
import { RootStore } from '../../../../../../store'
import { Loader, LoaderWrapper } from '../../../../../../@components/Loader'

const CARD_SIZES = new Map<IBreakpoints, [number, number]>([
  [BreakpointsEnum.DESKTOP, [123, 170]],
  [BreakpointsEnum.TABLET, [123, 166]],
  [BreakpointsEnum.MOBILE, [123, 145]],
])

const CARD_OFFSETS = new Map([
  [BreakpointsEnum.DESKTOP, 8],
  [BreakpointsEnum.TABLET, 8],
  [BreakpointsEnum.MOBILE, 8],
])

export const CollectionList = observer(() => {
  const collectionStore = RootStore.collectionStore
  const favouritesStore = RootStore.favouritesStore

  const sliderRef = useRef<any>()
  const [seed, setSeed] = useState<number>(1)
  const [selected, setSelected] = useState<MetaResponseApi | null>(collectionStore.collectionsValid[0])

  const onSelect = useCallback((idx: number) => setSelected(collectionStore.collectionsValid[idx]), [setSelected])

  const onAddToFavourites = useCallback(async () => {
    if (!selected) return

    collectionStore.removeFromCollections(selected.gid)
    favouritesStore.addToFavourites({
      gid: selected.gid as string,
      imageUrl: selected.verticalImageUrl as string,
      title: selected.title as string,
      type: selected.type as ShelfItemApiTypeEnum,
    })
    await onNext(false, true);
  }, [selected, sliderRef, seed])
  
  const onNext = useCallback(async (addToNegative: boolean, addToShowed: boolean) => {
    setSkipFetchStatus(true)
    await collectionStore.fetchPositiveFavourites(favouritesStore.positive, 8, seed, addToNegative, addToShowed)
  }, [seed])

  const [skipFetchStatus, setSkipFetchStatus] = useState(false)
  async function onAdd() {
    gtm.pushEvent({
      event: 'hello_button_click', 
      event_name: 'hello_button_click', 
      button_id: '2',
      button_text: 'Добавить',
    })
    
    await onAddToFavourites()
  }

  return (
    <Container>
      <CollectionHeading 
        as='h2' 
        family='MTS Sans' 
        children='Рекомендуем к просмотру' 
      />

      {(!skipFetchStatus && collectionStore.fetchStatus === 'pending') ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : !collectionStore.collectionsValid.length ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Paragraph align="center" style={{width: '65%'}}>
            Мы не придумали, что вам порекомендовать - пошли пересматривать наши каталоги и снимать новые фильмы
            🤔🎥<br/>
            А вы пока попробуйте составить новый список
          </Paragraph>
        </div>
      ) : (   
        <>          
          <Slider
            seed={seed}
            sliderRef={sliderRef}
            CARD_SIZES={CARD_SIZES}
            CARD_OFFSETS={CARD_OFFSETS}
            // elements={StubCollections}
            elements={collectionStore.collectionsValid}
            Poster={CollectionElement}
            selected={selected}
            onAdd={onAdd}
            onSelect={onSelect}
            onNext={() => onNext(true, false)}
            recsLeft={collectionStore.recsLeft}
          />

          <AnimatePresence>
            {!!selected && (
              <Details onAddToFavourites={onAddToFavourites} selected={selected} />
            )}
          </AnimatePresence>
        </>   
      )}
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  max-width: 100%;
  overflow: hidden;
  color: white;
`

const CollectionHeading = styled(Heading)`
  padding: 0 24px;
  font-weight: 700;
  font-size: 24px;

  ${mediaQueries.mobile} {
    font-size: 17px;
  }
`