import { MetaResponseApi } from 'mgw-api'

export * from './functions'

export const StubCollections: MetaResponseApi[] = [
    {
      "gid":"glo_serial_mts_65147000","title":"Капкан на судью",
      "imageUrl":"https://static.kion.ru/content/mts/series/65147000/posters/VERTICAL_187615709db93db6cf8ecb22292ac631.jpeg",
      "ageRestriction":"16","type":"SERIES","link":{"linkType":"SERIES","seriesContentId":"611109758", "seriesSlug":"kapkan-na-sudyu"},"ratings":{"kinopoisk":7.0,"imdb":null,"mts":9.2},
      "packages":["svod_entertainment_v1","svod_entertainment_v2"],"saleModels":["SVOD"],
      "isPurchased":false,"posterLabel":{"labelText":"Подписка","labelColor":"#A86EA7"},
      "partnerLogoUrl":"{bu}/poster/202112/20211202/75/20211202071124117qq2.png",
      "boughtStatus":"UNPURCHASED"
    }, {
      "gid":"glo_serial_mts_65137000","title":"Меньшее зло",
      "imageUrl":"https://static.kion.ru/content/mts/series/65137000/posters/VERTICAL_abe46bc35fc160c78e6f5bf2557f904d.jpeg",
      "ageRestriction":"16","type":"SERIES","link":{"linkType":"SERIES","seriesContentId":"739226939", "seriesSlug":"menshee-zlo"},"ratings":{"kinopoisk":6.9,"imdb":null,"mts":7.8},
      "packages":["svod_entertainment_v1","svod_entertainment_v2"],
      "saleModels":["SVOD"],"isPurchased":false,"posterLabel":{"labelText":"Подписка","labelColor":"#A86EA7"},
      "partnerLogoUrl":"{bu}/poster/202112/20211202/75/20211202071124117qq2.png","boughtStatus":"UNPURCHASED"
    },
    {
      "gid":"glo_serial_mts_64815000","title":"Почка",
      "imageUrl":"https://static.kion.ru/content/mts/series/64815000/posters/VERTICAL_6257f8302f827560c43d456cccaab2f7.jpeg",
      "ageRestriction":"18","type":"SERIES","link":{"linkType":"SERIES","seriesContentId":"489793705","seriesSlug":"pochka"},
      "ratings":{"kinopoisk":7.2,"imdb":7.1,"mts":8.6},"packages":["svod_entertainment_v1","svod_entertainment_v2"],
      "saleModels":["SVOD"],"isPurchased":false,"posterLabel":{"labelText":"Подписка","labelColor":"#A86EA7"},
      "partnerLogoUrl":"{bu}/poster/202112/20211202/75/20211202071124117qq2.png","boughtStatus":"UNPURCHASED"
    },
] as any