import { useState, useRef, useEffect, ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { ReactComponent as SearchIcon } from '../../../assets/icon/search.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icon/cross-new.svg'
import { RootStore } from '../../../store'
import { gtm } from '../../../helpers/GTMService'

interface ISearchBar {
  onEscape?: () => void
  classForWrapper?: string;
}

export const SearchBar: FC<ISearchBar> = observer(({ onEscape, classForWrapper }) => {
  const searchStore = RootStore.searchStore
  const { 
    isSearchOpen,
    query, 
    setQuery, 
    clearQuery, 
    fetchSearch 
  } = searchStore

  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value)
  }

  useEffect(() => {
    if (query) fetchSearch(query)
  }, [query])

  function searchEvent() {
    if (!query) return;
    gtm.pushEvent({
      event: 'hello_search', 
      event_name: 'hello_search', 
      term: query,
    })
  }

  useEffect(() => {
    if (isSearchOpen) inputRef.current?.focus()
  }, [isSearchOpen])

  function clear() {
    clearQuery()
    inputRef.current?.focus()
  }

  function checkIfEscapePressed(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Escape') return
    onEscape?.()
  }

  const isMobile = window.innerWidth < 768

  return (
      <InputWrapper className={classForWrapper}>
        <IconWrapper isFocus={isFocus}>
          <SearchIcon />
        </IconWrapper>
        <InputField
          ref={inputRef} 
          value={query} 
          onChange={onChange}
          autoFocus
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false)
            searchEvent()
          }}
          onKeyDown={onEscape && checkIfEscapePressed} // TODO: Ещё надо обработать жест или кнопку Назад на смартфоне
          placeholder={!isMobile ? 'Введите название любимых фильмов и сериалов' : 'Фильмы, сериалы'}
          query={!!query}
        />
        {query && (
          <DeleteButton onClick={clear}>
            <CrossIcon />
          </DeleteButton>
        )}
      </InputWrapper>
  )
})

const InputWrapper = styled.div`
  position: relative;
`

interface IInputField {
  query: boolean
}

const InputField = styled.input<IInputField>`
  color: var(--color-secondary-grey);
  background: var(--color-secondary-blue);
  padding: 14px ${p => p.query ? '40px' : '14px'} 14px 40px;
  width: 100%;
  max-width: 100%;
  height: 44px;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  font-size: 1rem;

  &::placeholder {
    color: var(--color-secondary-grey);
  }

  &:focus {
    border: 1px solid var(--color-white-100);
    color: var(--color-white-100);
  }
`

interface IIconWrapper {
  isFocus: boolean
}

const IconWrapper = styled.div<IIconWrapper>`
  position: absolute;
  width: fit-content;
  /* padding-top: 5px; */
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);

  & svg {
    fill: ${p => p.isFocus ? 'var(--color-white-100)' : 'var(--color-secondary-grey)'};
  }
`

const DeleteButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: fit-content;
  padding-top: 3px;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
`
